.intent-editor {
    .actions {
        width: 80px; }
    .icon-hover-fx {
        font-size: 1.2rem; }
    .DraftEditor-root {
        width: 100%;
        font-size: 1.2rem; }
    .actions,
    .kt-section__content {
        display: flex;
        justify-content: space-between;
        align-items: center; } }
