//
// Project 1
//



.kt-widget.kt-widget--project-1 {
    display: flex;     
    flex-direction: column;
    height: calc(100% + 20px);     

    .kt-widget__head { 
        display: flex;     
        justify-content: space-between;  
        flex-wrap: wrap;  
        padding: kt-get($kt-portlet, space, desktop);

        .kt-widget__label {
            display: flex; 

            .kt-widget__media {
                .kt-media {  
                    .kt-widget__icon {                    
                        max-width: 62px;
                        height: 62px;
                        margin-top: -0.5em;
                        margin-left: -0.5em;                                                        
                    }                 
                    
                    img {
                        width: 65px;
                    }
                }
    
                &.kt-widget__media--m {
                    margin-top: 1.5px;
                }
            }
    
            .kt-widget__info {
                padding: 0.25em 0 0 1em;                
    
                .kt-widget__title {
                    font-size: 1.3em;
                    color: kt-base-color(label, 4);
                    font-weight: 600;
    
                    &:hover {
                        color: kt-brand-color();
                        transition: color 0.3s ease;
                    }
                }
    
                .kt-widget__desc {
                    padding-top: 0.4em;
                    color: kt-base-color(label, 3);
                    font-weight: 500;
                    display: block;
                }
            }
        }
        
        .kt-widget__toolbar {
            display: flex;
            align-items: flex-start;
        }
    }

    .kt-widget__body {
        padding: kt-get($kt-portlet, space, desktop);
        height: 100%;
        padding-top: 0 !important;

        .kt-widget__stats {             
            display: flex;   
            flex-wrap: wrap;

            .kt-widget__item {
                padding-top: 1em;   

                .kt-widget__date {
                    color: kt-base-color(label, 3);
                    font-weight: 500;
                    padding-bottom: 1em;
                    display: block;
                }             

                .kt-widget__subtitel {
                    color: kt-base-color(label, 3);
                    font-weight: 500;
                    display: block;
                }

                .kt-widget__progress {
                    width: 100%;
                    margin: 1.4em 0 0.5em 0;                 

                    .kt-widget__stat {
                        padding-left: 0.7em;
                        color: kt-base-color(label, 4);
                        font-weight: 600;
                    }
                } 
                
                &:not(:first-child):not(:last-child) {
                    margin: 0 2.2em
                }

                &:last-child {
                    padding-left: 1em;
                }
            }             
        }

        .kt-widget__container {
            display: flex; 
            align-items: center;
            margin: 2.7em 0 1.2em 0;

            .kt-widget__subtitel {
                color: kt-base-color(label, 3);
                font-weight: 500;                
            }

            .kt-widget__progress {
                width: 100%;
                margin: 0 1em;                            

                .kt-widget__stat {
                    padding-left: 0.7em;
                    color: kt-base-color(label, 4);
                    font-weight: 600;
                }
            } 
        }

        .kt-widget__text {
            color: kt-base-color(label, 3);
            font-weight: 500;
            margin-top: 2.7em;            
            display: block;
        }

        .kt-widget__content {
            padding: 1em 0 1.3em 0;
            display: flex;   
            flex-wrap: wrap;  
            //align-items: center;

            .kt-widget__details {
                margin-right: 3.7em;
                padding-top: 1em;
                display: flex;
                flex-direction: column;

                .kt-widget__subtitle {
                    color: kt-base-color(label, 3);
                    font-weight: 600;
                    padding-bottom: 1.1em;                                      
                }

                .kt-widget__value {
                    color: kt-base-color(label, 4);
                    font-weight: 600;
                    font-size: 1.2em;

                    span {
                        color: kt-base-color(label, 2);
                    }
                }

                .kt-badge {                   
                    margin: 0.3em 0 0 7px;
                }

                &:last-child {
                    margin-right: 0;

                    .kt-widget__subtitle {                        
                        margin-top: 0em;                   
                    }
                }

                .kt-media-group {
                    margin-top: -0.5em
                }
            }
        }
    }

    .kt-widget__footer {
        border-top: 1px solid kt-base-color(grey, 2);
        width: 100%;

        .kt-widget__wrapper {
            padding: kt-get($kt-portlet, space, desktop); 
            display: flex;
            justify-content: space-between;  
            flex-wrap: wrap;
            align-items: center;

            .kt-widget__section {
                display: flex;
                align-items: center;

                .kt-widget__blog {
                    display: flex;
                    align-items: center;

                    i {
                        font-size: 1.3em;
                        color: #d2d8e8;
                    }

                    .kt-widget__value {                    
                        font-weight: 700;  
                        padding-left: 0.6em;
                                      
                    }

                    &:last-child {
                        padding-left: 1.7em;
                    }
                }
            }             
        }            
    }
}


@include kt-tablet-and-mobile {
    .kt-widget.kt-widget--project-1 {
        .kt-widget__head {
            padding-bottom: 1em;
            padding: kt-get($kt-portlet, space, mobile);
            
            .kt-widget__media {
                .kt-media {                
                    @include kt-media-size(50px, 1.2em);  
                    
                    img {
                        width: 50px;
                    }

                    &.kt-media--md {
                        @include kt-media-size(45px, 1em); 
                        
                        img {
                            width: 47px;
                        }
                    }

                    .kt-widget__icon {                        
                        margin-top: -0.7em;   
                        width: 62px;                                                                                                
                    }     
                }

                &.kt-widget__media--m {
                    margin-top: 1px;                
                }
            }

            .kt-widget__info {
                padding: 0.2em 0 0 1em;             
            }
        }

        .kt-widget__body {
            padding: kt-get($kt-portlet, space, mobile);
            
            .kt-widget__stats {
                .kt-widget__item {              
                    &:not(:first-child):not(:last-child) {
                        margin: 0 1.5em
                    } 
                    
                    &:last-child {
                        padding-left: 0;
                    }
                }             
            }

            .kt-widget__text {          
                margin-top: 1.5em;
                margin-bottom: 1em;          
            }

            .kt-widget__content {
                padding: 0.5em 0 1em 0;                

                .kt-widget__details {
                    margin-right: 1.2em;                

                    &:last-child {
                        margin-right: 0;  
                        
                        .kt-widget__subtitle {                        
                            margin-top: 0em;                   
                        }
                    }
                }
            }
        }

        .kt-widget__footer {
            .kt-widget__wrapper  {
                padding: kt-get($kt-portlet, space, mobile);

                .kt-widget__section {
                    padding-right: 1em;
                    
                    .kt-widget__blog {
                        .kt-widget__value {                    
                            padding-left: 0.4em;                  
                        }

                        &:last-child {
                            padding-left: 1.2em;
                        }
                    }
                }               
            }                
        }
    }    
}

 