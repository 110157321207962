//
// Quick Panel
//





$kt-offcanvas-panel: (
	self: (
		bg-color: #fff,
		shadow: 0px 0px 28px 0px rgba(82,63,105,0.13),

		offcanvas: (
			layout: (
				self: (
					zindex: 1001,
					direction: right,
					transition:	all .3s ease,
					width: 425px
				),

				close: (
					size: 25px,
					font-size: 1.4em
				)
			),

			skin: (
				overlay: (
					bg-color: rgba(#000000, 0.1),
				)
			)
		)
	)
);

// Base
.kt-offcanvas-panel {
	background-color: kt-get($kt-offcanvas-panel, self, bg-color);
	box-shadow: kt-get($kt-offcanvas-panel, self, shadow);
	padding: 2.5em;

	// Head
	.kt-offcanvas-panel__head {
		display: flex;
		justify-content: space-between;
		align-items: center;
		margin-bottom: 2em;

		.kt-offcanvas-panel__title {
			font-size:  1.4em;
			font-weight: 500;
			color: kt-base-color(label, 4);
			margin: 0;
			padding: 0;

			small {
				color: kt-base-color(label, 2);
				font-size: 1em;
			}
		}

		.kt-offcanvas-panel__close {
			display: flex;
			justify-content: center;
			align-items: center;
			width: 28px;
			height: 28px;
			background-color: kt-base-color(grey, 1);
			@include kt-transition();

			i {
				@include kt-transition();
				font-size: 0.8em;
				color: kt-base-color(label, 2);
			}

			@include kt-rounded {
				border-radius: $kt-border-radius;
			}

			&:hover {
				@include kt-transition();
				background-color: kt-brand-color();

				i {
					@include kt-transition();
					color: kt-brand-color(inverse);
				}
			}
		}
	}	

	// Content
	.kt-offcanvas-panel__body {
		position: relative;

		.kt-offcanvas-panel__section {
			padding: 0;
			font-size: 1.3em;
			font-weight: 500;
			color: kt-base-color(label, 3);
			margin: 2em 0 1.5em 0;

			&.kt-offcanvas-panel__section--first {
				margin-top: 0;
			}
		}
	}
}

// Build offcanvas mode
@include kt-offcanvas-build(kt-offcanvas-panel, default, kt-get($kt-offcanvas-panel, self, offcanvas) );

// Desktop view
@include kt-desktop {
	.kt-offcanvas-panel {
		.kt-offcanvas-panel__body {
			padding-right: 1.5em;
			margin-right: -1.5em;
		}
	}
}

// Mobile view
@include kt-tablet-and-mobile {
	.kt-offcanvas-panel {
		padding: 1em;
	}
}