.action-config {
    border: 3px solid #ebedf2;
    border-radius: 4px;
    height: 100%;
    position: relative;
    .custom-toggle {
        cursor: pointer;
        padding: 1rem;
        transition: background-color 0.4s ease;
        &:hover {
            background-color: #f7f8fa; } }
    .schema-editor {
        padding-bottom: 1rem; }
    .remove-action {
        position: absolute;
        right: 0.8rem;
        top: 0.8rem;
        color: rgba(0,0,0,0.2);
        font-size: 1.5rem;
        cursor: pointer;
        transition: color 0.4s ease;
        &:hover {
            color: #5d78ff; } } }

