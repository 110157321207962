.node-item-title {
    padding: 0.5rem 0 0.8rem;
    .node-no-title {
        opacity: 0.3; }
    .kt-label-font-color-1 {
        font-size: 12px; }
    .node-title,
    .kt-font-primary,
    .kt-badge {
        font-size: 13px; } }
