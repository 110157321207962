.output-input {
    position: relative;
    width: auto;
    padding: 0;
    font-size: 1.2rem;

    .DraftEditor-editorContainer {
        position: relative;
        z-index: 1;
        & > .public-DraftEditor-content {
            padding: 4.2rem; } }
    .public-DraftEditorPlaceholder-root {
        position: absolute;
        opacity: 0.7;
        padding: 4.2rem;
        z-index: 0; } }

.editor-side-toolbar {
    position: absolute;
    left: 10px;
    cursor: pointer;
    top: 0px;

    .toolbar-icon {
        background: #FFFFFF;
        position: relative;
        z-index: 2;
        cursor: pointer;
        & > svg {
            font-size: 2rem; } }

    .toolbar-wrap {
        position: absolute;
        top: 0;
        left: 20px;
        background: #FFFFFF;
        box-shadow: 0px 4px 16px 0px rgba(153, 153, 153, 0.4);
        padding-left: 20px;
        z-index: 1;
        .btn-group {
            & > .btn {
                display: inline-flex;
                white-space: nowrap; } } }

    .kt-input-icon {
        min-width: 300px;
        svg {
            font-size: 1.8rem !important;
            color: rgba(0,0,0,0.3);
            transition: color 0.4s ease;
            &:hover {
                color: #0abb87; } } } }

