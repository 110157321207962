.entity-dropdown {
    .dropdown-menu {
        width: 300px; }
    .search {
        padding: 10px; }
    .kt-nav {
        overflow-y: auto;
        max-height: 200px;
        padding: 10px 0 0;
        .kt-nav__link-badge {
            font-size: 1.4rem; }
        .kt-nav__link {
            padding-top: 0.8rem;
            padding-bottom: 0.8rem; } }

    .fa-check {
        color: #5d78ff;
        transform: scale(0);
        transition: transform 0.3s ease; }

    .fa-times-circle {
        transition: color 0.3s ease, transform 0.3s ease;
        color: rgba(0,0,0,0.2);
        transform: scale(0.9); }

    .kt-nav__item {
        &:hover {
            .fa-times-circle {
                color: #fd397a;
                transform: scale(1); } }
        &:hover,
        &.active {
            .fa-check {
                transform: scale(1); } } } }


