.select2 {
    & > div {
        box-shadow: none; }
    &.is-invalid {
        & > div {
            border-color: #fd397a; }
        + .error {
            display: block; } }
    .select2__menu {
        box-shadow: 0 1px 4px rgba(0,0,0,0.09); } }
