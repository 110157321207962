//
// Support Center/License
//




// Custom pages SASS files are compiled per demo as 
// each demo has own config settings that may override the global theme config
// and pages may slighty different on demos


// Global theme config(sass/theme/core/config.scss)
@import "../../config";

// Demo config(e.g: sass/theme/layout/[demo]/config.scss) that overrides global theme config.
// Do not change this path, build tool automatically finds current demo's config file.	
// @import "config.scss";

// Base
.kt-sc-license {
	background-position: center top;
	background-size: cover;
	background-repeat: no-repeat;
	
	.kt-sc__top{
		display: flex;
		padding: 6em 0 10em;
		align-items: center;

		.kt-sc__title {
			margin-bottom: 0;
			font-size: 1.85em;
			font-weight: 600;
			color: kt-state-color(brand, inverse);
		}

		.kt-sc__nav{
			margin: auto 0 auto auto;
			
			.kt-link{
				font-size: 1.3em;
				font-weight: 400;
			}
		}
	}
}