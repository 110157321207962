.move-node-actions {
    position: absolute;
    background: rgba(255,255,255,0.95);
    width: 100%;
    left: 0;
    top: 0;
    height: 100%;
    opacity: 0;
    transition: all 0.4s ease;
    border-radius: 4px;
    box-shadow: 0 0 0 0px rgba(0,0,0,0.1);
    flex: 1 0 auto;

    &.hover,
    &.isCurrent {
        opacity: 1;
        box-shadow: 0 0 0 3px rgba(0,0,0,0.1); }

    .btn-label-success {
        opacity: 0;
        transition: opacity 0.4s ease; }

    &.hover {
        box-shadow: 0 0 0 3px rgba(10,187,135,0.5);
        .btn-label-success {
            opacity: 1;
            transition-delay: 0.2s; } }

    .row {
        height: 100%;
        align-items: center;
        text-align: center; }

    .col {
        font-size: 1rem;
        align-items: center; } }

.kt-portlet__head-icon + .kt-portlet__head-title {
    .move-node-actions:not(.isCurrent) {
        .row {
            padding-left: 35px; } } }



