.utterances-list {
    padding: 0;
    .utterance-entity {
        padding: 0 5px;
        border-radius: 3px; }
    .entity {
        padding: 0.4rem 0.7rem;
        border-radius: 3px;
        color: #646c9a;
        font-size: 0.9rem;
        white-space: nowrap;
        display: inline-block; }

    .kt-widget1__info,
    .kt-widget1__desc {
        max-width: 100%; }

    .kt-widget1__item {
        .entity {
            margin-right: 1rem;
            margin-bottom: 1rem; } }

    .utterance-action {
        width: 65px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .icon-hover-fx {
            transform: scale(0); } }
    .kt-widget1__item:hover {
        .utterance-action {
            .icon-hover-fx {
                transform: scale(1);
                &:hover {
                    transform: scale(1.2); } } } }
    .intent-editor {
        margin: 0;
        padding: 1.1rem 0;
        &:first-child {
            padding: 0 0 1.1rem; }
        &:last-child {
            border-bottom: none; } } }

.intent-editor {
    padding: 0 0 1.1rem;
    margin: 0 0 1.1rem;
    border-bottom: 0.07rem dashed #ebedf2; }


