.btn {
    svg[class*=" fa-"] {
        margin-right: .3rem; }
    svg.fa-ellipsis-v {
        font-size: 2rem; } }
.btn-icon {
    svg[class*=" fa-"] {
        margin-right: 0; } }

.kt-input-icon > .kt-input-icon__icon [class*=" fa-"] {
    font-size: 1em; }

.no-text {
    opacity: 0.5; }

.svg-inline-icon {
    color: #5d78ff;
    display: inline-block;
    font-size: inherit;
    height: 2em;
    overflow: visible;
    width: 2em; }

.kt-widget {
    &.kt-widget--project-1 {
        .kt-widget__head {
            flex-wrap: nowrap;
            .btn.btn-icon {
                .svg-inline--fa {
                    font-size: 1.6rem; } } } } }

.react-tagsinput {
    box-sizing: border-box;
    display: block;
    margin: 0;
    position: relative;
    vertical-align: middle;
    background-color: #fff;
    min-height: 32px;
    user-select: none;
    cursor: text;
    border: 1px solid #e2e5ec;
    border-radius: 4px;
    padding: 10px 1rem 0;
    & > span {
        display: flex;
        vertical-align: top;
        flex-wrap: wrap; }
    &.react-tagsinput--focused {
        border-color: #9aabff; }
    .react-tagsinput-input {
        margin: 0 0 10px;
        padding: 0;
        border: none;
        outline: none; }
    .btn {
        position: relative;
        .svg-inline--fa {
            position: absolute;
            top: -4px;
            right: -4px;
            margin: 0;
            cursor: pointer;
            transform: scale(0);
            transition: transform 0.4s ease;
            background: rgba(0,0,0,0.1);
            border-radius: 10px;
            width: 15px;
            height: 15px;
            padding: 2px;
            display: block; }
        &:hover {
            .svg-inline--fa {
                transform: scale(1); } } } }
.dropdown {
    .dropdown-menu {
        padding: 0; }
    .custom-toggle {
        &::after {
            display: none; } } }

.DraftEditor-root {
    figure {
        margin: 0; } }

.no-hover {
    color: inherit !important;
    background-color: inherit !important;
    cursor: default !important; }

.kt-datatable + .kt-datatable.kt-datatable--default > .kt-datatable__pager {
    padding-top: 0; }
.kt-login__link,
.kt-login__account-link,
.kt-link {
    cursor: pointer; }

.table-type1 {
    position: relative;
    .kt-datatable__body {
        min-height: 300px; } }

.custom-modal {
    .swal2-content {
        margin: 0; } }

.kt-media {
    &.kt-media--35 {
        span {
            width: 35px;
            height: 35px;
            font-size: 1.2rem; } } }
.kt-wizard-v1 .kt-wizard-v1__wrapper .kt-form {
    width: 100%; }

.is-invalid + .error,
.is-invalid + .invalid-feedback {
    display: block; }

.kt-spinner.kt-spinner--input.kt-spinner--right:before {
    z-index: 1; }

.kt-login.kt-login--v3 .kt-login__wrapper .kt-login__container .kt-form .is-invalid + .invalid-feedback {
    font-weight: 500;
    font-size: 0.9rem;
    padding-left: 1.6rem; }
