//
// Support Center/Home 1
//




// Custom pages SASS files are compiled per demo as 
// each demo has own config settings that may override the global theme config
// and pages may slighty different on demos


// Global theme config(sass/theme/core/config.scss)
@import "../../config";

// Demo config(e.g: sass/theme/layout/[demo]/config.scss) that overrides global theme config.
// Do not change this path, build tool automatically finds current demo's config file.	
// @import "config.scss";

// Base
.kt-sc {
	background-position: center top;
	background-size: cover;
	background-repeat: no-repeat;
	margin-bottom: 2em;

	.kt-sc__top {
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding-top: 2em;
		padding-bottom: 2em;
		border-bottom: 1px solid kt-state-color(brand, inverse);

		.kt-sc__title {
			margin-bottom: 0;
			font-size: 1.45em;
			color: kt-base-color(label, 4);
		}

		.kt-sc__nav {

			.kt-link{
				margin-left: 2em;
				font-size: 1.1em;
				font-weight: 400;

				&:first-child{
					margin-left: 0;
				}
			}
		}
	}	

	.kt-sc__bottom{
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		padding-top: 10em;
		padding-bottom: 10em;

		.kt-sc__heading{
			margin-bottom: 4em;
		}

		.kt-sc__form {
			.input-group{
				width: 630px;
				box-shadow: 0px 0px 9px 0px rgba(122,104,181,0.09);

				.input-group-text{
					border-top-right-radius: 0;
					border-bottom-right-radius: 0;
					border: none;
					padding: 0.65em 2em;
					background-color: kt-state-color(brand, inverse);
				}

				.form-control{
					border: none;
					padding: 2.5em 0;
					font-weight: 400;
					font-size: 1.1em;
				}
			}
		}
	}
}


@include kt-tablet-and-mobile(){
	.kt-sc {
		.kt-content--fit-top & {
			margin-top: -15px;
		}	

		.kt-sc__top{
			.kt-sc__nav {
				.kt-link{
					margin-left: 1em;

					&:first-child{
						margin-left: 0;
					}
				}
			}
		}

		.kt-sc__bottom{
			.kt-sc__form{
				.input-group{
					width:100%;
				}
			}
		}
	}
}