.kt-widget1 {
    .values-list-item {
        align-items: top; } }

.values-list-item {
    .synonyms {
        margin: 0;
        .btn {
            margin-right: 1rem;
            margin-bottom: 1rem;
            position: relative;
            .icon-hover-fx {
                position: absolute;
                display: inline-block;
                background: rgba(0,0,0,0.1);
                top: -10px;
                right: -10px;
                overflow: hidden;
                border-radius: 10px;
                transform: scale(0); }
            &:hover {
                .icon-hover-fx {
                    transform: scale(1); } } } }
    .kt-widget1__title {
        width: 280px; } }
.add-synonym-wrap {
    width: 280px; }
