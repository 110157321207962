//
// Bootstrap Notify
//





.alert[data-notify] {
    min-width: 300px;

    .close {
        right: 10px !important;
        padding: 0.25em 0 0 2em;

        @include kt-close-icon();
        font-weight: 300;

        &:before {
            font-size: 1.1em;
            color: #fff;
            opacity: 0.7;
            @include kt-transition();
        }

        &:hover {
            &:before {
                opacity: 1;
                @include kt-transition();
            }
        }
    }

    @include kt-tablet {
        max-width: 70%;
    }

    @include kt-mobile {
        max-width: 90%;
    }

    &[data-notify-position=top-center],
    &[data-notify-position=bottom-center] {
        width: 30%;

        @include kt-tablet {
            width: 70%;
        }

        @include kt-mobile {
            width: 90%;
        }
    }

    .icon {
        position: absolute;
    }

    @include kt-icons-style( font-size, (lineawesome: 1.8em, fontawesome: 1.6em, flaticon: 1.8em) );
    @include kt-icons-style( margin-top, (lineawesome: -0.1em, fontawesome: -0.1em, flaticon: -0.4em) );

    [data-notify=title] {
        display: block;
        font-weight: 500;
    }

    .icon ~ [data-notify=title] {
        padding-left: 2.85em;
    }

    .icon ~ [data-notify=message] {
        display: inline-block;
        padding-left: 2.85em;
    }

    [data-notify=title]:not(:empty) ~ [data-notify=message] {
        margin-top: 0.2em;
    }

    .progress {
        margin-top: 0.5em;
        line-height: 0.5em;
        height: 0.5em;
    }
}
