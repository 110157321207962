//
// Widget16
//




// widget6 
.kt-widget6 {
    .kt-widget6__head {
        .kt-widget6__item {
            display: flex; 
            align-items: center;            
            margin-bottom: 1.07em;             

            > span {
                font-size: 1em;
                color: kt-base-color(label, 2);
                font-weight: 400;
                flex: 1;                 
                text-align: left;

                &:last-child {
                    text-align: right;                    
                }                  
            }
        }
    } 

    .kt-widget6__body {
        .kt-widget6__item {
            display: flex;             
            align-items: center;
            padding: 1.07em 0;                                 

            > span {                     
                color: kt-base-color(label, 3);
                font-weight: 400; 
                flex: 1;                 
                text-align: left;
                font-size: 1em;

                &:last-child {
                    text-align: right;                    
                }                 
            }  

            border-bottom: 1px dashed kt-base-color(grey, 2);

            &:last-child {
                border-bottom: 0;
            }
        }
    } 

    .kt-widget6__foot {
        .kt-widget6__action {
            text-align: right;
            margin-top: 1em;
        }
    }
}   
                                     
       
 