.analytics-details-modal {
    .swal2-popup {
        padding: 0; }
    .swal2-content {
        max-height: 80vh;
        min-height: 50vh;
        display: flex;
        flex-direction: column;
        text-align: left; }
    .kt-widget12 {
        padding: 1rem;
        border-bottom: 2px solid rgba(0,0,0,0.06); }
    .kt-chat {
        padding: 1rem; }
    .kt-widget12__content {
        font-size: 0.8em;
        padding: 0;
        .kt-widget12__item {
            margin-bottom: 1rem;
            &:last-child {
                margin-bottom: 0; }
            .kt-widget12__info {
                .kt-widget12__desc {
                    font-size: 1em; }
                .kt-widget12__value {
                    font-size: 1.2em; } } } } }
